import React, { useState, useEffect, useContext } from 'react';
import { db } from '../Firebase Functions/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { AuthContext } from '../hooks/AuthProvider';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  color: #296b1a;
`;

const InspectionItem = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Checklist = styled.ul`
  list-style: none;
  padding: 0;
`;

const ChecklistItem = styled.li`
  margin: 5px 0;
  color: ${({ checked }) => (checked ? 'green' : 'red')};
  font-weight: ${({ checked }) => (checked ? 'bold' : 'normal')};
`;

const TripInspectionHistory = () => {
  const { currentUser } = useContext(AuthContext);
  const [assignedVehicles, setAssignedVehicles] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedTruck, setExpandedTruck] = useState(null);

  useEffect(() => {
    if (!currentUser) return;

    const fetchAssignedVehicles = async () => {
      try {
        setLoading(true);

        // Fetch trucks assigned to the current user
        const trucksQuery = query(collection(db, 'Trucks'), where('assignedTo', '==', currentUser.email));
        const trucksSnapshot = await getDocs(trucksQuery);

        const assignedTrucks = trucksSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setAssignedVehicles(assignedTrucks);

        // Fetch trip inspections for assigned trucks
        if (assignedTrucks.length > 0) {
          const truckIds = assignedTrucks.map(truck => truck.id);
          const inspectionsQuery = query(collection(db, 'vehicleInspections'), where('truckId', 'in', truckIds));
          const inspectionsSnapshot = await getDocs(inspectionsQuery);

          const inspectionsData = inspectionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          setInspections(inspectionsData);
        }
      } catch (error) {
        console.error('Error fetching trip inspections:', error);
        setError('Failed to load trip inspections.');
      } finally {
        setLoading(false);
      }
    };

    fetchAssignedVehicles();
  }, [currentUser]);

  if (loading) return <p>Loading trip inspections...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container>
      <Title>Your Vehicle Trip Inspection History</Title>

      {assignedVehicles.length === 0 ? (
        <p>No vehicles assigned to you.</p>
      ) : inspections.length === 0 ? (
        <p>No trip inspections found for your assigned vehicles.</p>
      ) : (
        inspections.map((inspection) => (
          <InspectionItem
            key={inspection.id}
            onClick={() =>
              setExpandedTruck(expandedTruck === inspection.truckId ? null : inspection.truckId)
            }
          >
            <p><strong>Truck Name:</strong> {assignedVehicles.find(v => v.id === inspection.truckId)?.vehicleName || 'Unknown'}</p>
            <p><strong>Truck ID:</strong> {inspection.truckId}</p>
            <p><strong>Inspection Type:</strong> {inspection.inspectionType}</p>
            <p><strong>Date:</strong> {inspection.date}</p>
            <p><strong>Odometer:</strong> {inspection.odometer || 'N/A'}</p>
            <p><strong>Comments:</strong> {inspection.comments || 'None'}</p>

            {/* Expand checklist when clicked */}
            {expandedTruck === inspection.truckId && (
              <Checklist>
                <h4>Checklist</h4>
                {Object.entries(inspection.checklist).map(([item, checked]) => (
                  <ChecklistItem key={item} checked={checked}>
                    ✅ {item}
                  </ChecklistItem>
                ))}
              </Checklist>
            )}
          </InspectionItem>
        ))
      )}
    </Container>
  );
};

export default TripInspectionHistory;
