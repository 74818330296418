import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  background-color: #f9f9f9; /* Light background for contrast */
  min-height: 100vh; /* Full height for the container */
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  @media (min-width: 768px) {
    flex-direction: row; /* Change to row layout on larger screens */
    justify-content: center;
    flex-wrap: wrap;
  }
`;


const StyledButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.75rem 1.5rem; /* Increased padding */
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #219653; /* Darker green on hover */
    transform: translateY(-2px); /* Slight lift effect */
  }

  @media (max-width: 768px) {
    min-width: 100px; /* Minimum width for smaller screens */
  }
`;

const AddServiceUpdateButton = styled(StyledButton)``;
const SeeServiceUpdatesButton = styled(StyledButton)``;

const StyledTitle = styled.h2`
  font-size: 2.5rem; /* Larger title for desktop */
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const Modal = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 600px; /* Fixed width */
  height: 600px; /* Fixed height */
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  overflow-y: auto; /* Enable scrolling if content exceeds fixed height */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
  color: #333;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.5rem;

  &:hover {
    background: #ccc;
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 450px; /* Increased height for better video display */
  border: none;
`;

const ManagerDashboard = () => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const toggleVideoModal = () => {
    setVideoModalOpen(!isVideoModalOpen);
  };

  return (
    <Container>
      <StyledTitle>Manager Dashboard</StyledTitle>
      <ButtonContainer>
      <StyledButton type="button" onClick={toggleVideoModal}>
          Watch Tutorial Video
        </StyledButton>
        <StyledButton type="button" onClick={() => navigate('/tasks')}>
          Punch Lists
        </StyledButton>
        <StyledButton type="button" onClick={() => navigate('/vehicle-maintenance')}>
          Truck Maintenance
        </StyledButton>
        <AddServiceUpdateButton type="button" onClick={() => navigate('/manager-job-form')}>
          New Service Update
        </AddServiceUpdateButton>
        <SeeServiceUpdatesButton type="button" onClick={() => navigate('/job-list')}>
          Services Needing Approval
        </SeeServiceUpdatesButton>
        <StyledButton type="button" onClick={() => window.open('https://rotoloconsultants.quickbase.com/nav/app/brspiu9w6/table/buiiqevgk/action/td', '_blank')}>
          View Client Submitted Services
        </StyledButton>
        <StyledButton type="button" onClick={() => navigate('/upload-images')}>
          Marketing Images
        </StyledButton>
      </ButtonContainer>

      {/* Modal for external link */}
      <Modal isOpen={isModalOpen} onClick={toggleModal}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={toggleModal}>✖</CloseButton>
          <Iframe
            src="https://rotoloconsultants.quickbase.com/nav/app/brspiu9w6/table/buiiqevgk/action/td"
            title="Services Submitted to Client"
          />
        </ModalContent>
      </Modal>

      {/* Video Modal */}
      <Modal isOpen={isVideoModalOpen} onClick={toggleVideoModal}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={toggleVideoModal}>✖</CloseButton>
          <Iframe
            src="https://www.youtube.com/embed/LJoA59hKZbI" // Replace with your YouTube video ID
            title="Tutorial Video"
          />
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ManagerDashboard;