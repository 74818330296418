import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db } from '../../Firebase Functions/firebase';
import PropertyList from '../../QB Lists/PropertyList';
import './EditJob.css';

const storage = getStorage();

const EditJob = ({ jobData = {}, onSave, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editForm, setEditForm] = useState({
        ...jobData,
        imageUrls: jobData.imageUrls || {}
    });
    const [isOtherServiceChecked, setIsOtherServiceChecked] = useState(
        jobData.services?.includes('Other') || false
    );
    const [customServiceDescription, setCustomServiceDescription] = useState(
        jobData.customServiceDescription || ''
    );
    const [imageFiles, setImageFiles] = useState({});

    const serviceOptions = [
        'Turf Mow', 'String Trim/Edging', 'Blow/Cleanup', 'Irrigation Monitoring and Control',
        'Mulch Install', 'Annuals Install', 'Tree Pruning', 'Palm Pruning', 'Medjool Seedhead Pruning',
        'Remediation', 'Seasonal Color Watering', 'Shrub Pruning', 'Pond Maintenance', 'Other'
    ];

    useEffect(() => {
        setEditForm({
            ...jobData,
            imageUrls: jobData.imageUrls || {}
        });
    }, [jobData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditForm(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleServiceChange = (e) => {
        const { value, checked } = e.target;
        setEditForm(prev => ({
            ...prev,
            services: checked
                ? [...prev.services, value]
                : prev.services.filter(service => service !== value)
        }));

        if (value === 'Other') {
            setIsOtherServiceChecked(checked);
            if (!checked) {
                setCustomServiceDescription('');
            }
        }
    };

    const handleImageChange = (e) => {
        const { name, files } = e.target;
        if (files[0]) {
            const file = files[0];
            setEditForm(prev => ({
                ...prev,
                imageUrls: {
                    ...prev.imageUrls,
                    [name]: URL.createObjectURL(file)
                }
            }));
            setImageFiles(prev => ({
                ...prev,
                [name]: file
            }));
        }
    };

    const getStoragePathFromUrl = (url) => {
        const match = url.match(/o\/(.+)\?alt=media/); // Match the path between "o/" and "?alt=media"
        return match ? decodeURIComponent(match[1]) : null;
    };
    
    const handleImageRemove = async (imageKey) => {
        if (!jobData.id) {
            console.error("Error: jobData.id is undefined. Cannot remove image.");
            setError("Job ID is missing, so images cannot be removed.");
            return;
        }
    
        try {
            // Extract the storage path from the full URL
            const imageUrl = jobData.imageUrls[imageKey];
            const storagePath = getStoragePathFromUrl(imageUrl);
    
            if (!storagePath) {
                console.warn(`Invalid URL for image ${imageKey}: ${imageUrl}`);
                setError(`Invalid URL for image ${imageKey}. Cannot remove.`);
                return;
            }
    
            // Create a reference to the file in Firebase Storage
            const imageRef = ref(storage, storagePath);
    
            // Attempt to delete the file from Firebase Storage
            await deleteObject(imageRef);
    
            // Update the local state to reflect removal
            setEditForm(prev => ({
                ...prev,
                imageUrls: {
                    ...prev.imageUrls,
                    [imageKey]: null
                }
            }));
    
            // Update Firestore to remove the URL or set it to null
            const jobRef = doc(db, 'Services', jobData.id);
            await updateDoc(jobRef, {
                [`imageUrls.${imageKey}`]: null
            });
    
            // Remove the file from the imageFiles state
            setImageFiles(prev => {
                const updatedFiles = { ...prev };
                delete updatedFiles[imageKey];
                return updatedFiles;
            });
        } catch (error) {
            if (error.code === 'storage/object-not-found') {
                console.warn(`Image ${imageKey} does not exist in storage, updating Firestore only.`);
                const jobRef = doc(db, 'Services', jobData.id);
                await updateDoc(jobRef, {
                    [`imageUrls.${imageKey}`]: null
                });
            } else {
                console.error(`Error removing image for ${imageKey}:`, error);
                setError(`Failed to remove image for ${imageKey}. Please try again.`);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        try {
            const updatedData = { ...editForm };
            const newImageUrls = { ...editForm.imageUrls };

            if (!jobData.id) {
                console.error("Error: jobData.id is undefined. Cannot upload images.");
                setError("Job ID is missing, so images cannot be uploaded.");
                setIsSubmitting(false);
                return;
            }

            for (const [key, file] of Object.entries(imageFiles)) {
                if (file) {
                    try {
                        const imageRef = ref(storage, `images/${jobData.id}/${key}`);
                        await uploadBytes(imageRef, file);
                        const url = await getDownloadURL(imageRef);
                        newImageUrls[key] = url;
                    } catch (uploadError) {
                        console.error(`Error uploading image for ${key}:`, uploadError);
                        setError(`Failed to upload image for ${key}. Please try again.`);
                        newImageUrls[key] = null;
                    }
                }
            }

            updatedData.imageUrls = newImageUrls;
            const jobRef = doc(db, 'Services', jobData.id);
            await updateDoc(jobRef, updatedData);

            onSave(updatedData);
            onClose();
        } catch (error) {
            console.error('Error updating job:', error);
            setError('Failed to update job. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleAddImageField = () => {
        const newKey = `image_${Object.keys(editForm.imageUrls).length + 1}`;
        setEditForm(prev => ({
            ...prev,
            imageUrls: {
                ...prev.imageUrls,
                [newKey]: null
            }
        }));
    };

    const handleCustomServiceDescriptionChange = (e) => {
        const { value } = e.target;
        setCustomServiceDescription(value);
        setEditForm(prev => ({
            ...prev,
            customServiceDescription: value
        }));
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">Error: {error}</div>;

    return (
        <div className="edit-job-modal">
            <div className="edit-job-content">
                <h2>Edit Service Details</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Supervisor Name:</label>
                        <input
                            type="text"
                            name="supervisorName"
                            value={editForm.supervisorName}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="form-group"></div>
                         <input
                            type="date"
                            name="date"
                            value={editForm.date}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                    <PropertyList 
                     onSelect={(property) => 
                     setEditForm(prev => ({ ...prev, property: property["Property Name"] }))
                    }
                     required
                   />

                    </div>
                    <div className="services-sections">
                        <label>Services:</label>
                        <div className="services-checkbox-group">
                            {serviceOptions.map((service) => (
                                <div key={service} className="checkbox-item">
                                    <input
                                        type="checkbox"
                                        id={service}
                                        value={service}
                                        checked={editForm.services.includes(service)}
                                        onChange={handleServiceChange}
                                    />
                                    <label htmlFor={service}>{service}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    {isOtherServiceChecked && (
                        <div className="form-group">
                            <label>Other Service Description:</label>
                            <textarea
                                name="customServiceDescription"
                                value={customServiceDescription}
                                onChange={handleCustomServiceDescriptionChange}
                                required
                            />
                        </div>
                    )}
                    <div className="form-group">
                        <label>Notes:</label>
                        <textarea
                            name="notes"
                            value={editForm.notes}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Images:</label>
                        <div className="image-upload-group">
                            {Object.keys(editForm.imageUrls).map((key) => (
                                <div key={key} className="image-preview">
                                    {editForm.imageUrls[key] ? (
                                        <>
                                            <img src={editForm.imageUrls[key]} alt={`${key}`} className="image-thumbnail" />
                                            <button
                                                type="button"
                                                onClick={() => handleImageRemove(key)}
                                                className="remove-image-button"
                                            >
                                                Remove
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <div className="image-placeholder">No image</div>
                                            <input
                                                type="file"
                                                name={key}
                                                onChange={handleImageChange}
                                                accept="image/*"
                                                className="change-image-input"
                                            />
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                        <button type="button" onClick={handleAddImageField} className="add-image-button">
                            Add Another Image
                        </button>
                    </div>
                    <div className="button-group">
                        <button type="submit" className="submit-button" disabled={isSubmitting}>
                            {isSubmitting ? 'Saving...' : 'Save Changes'}
                        </button>
                        <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditJob;
