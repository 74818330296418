import React, { useState, useEffect, useRef, useContext } from "react";
import SignatureCanvas from "react-signature-canvas";
import { db } from '../Firebase Functions/firebase';
import { useTranslation } from 'react-i18next';
import { collection, getDocs, doc, updateDoc, addDoc, getDoc, setDoc } from "firebase/firestore";
import "./ChecklistForm.css";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../hooks/AuthProvider";
import { useLocation } from 'react-router-dom';




const ChecklistForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const [mileage, setMileage] = useState(""); // State to hold fetched mileage



  // Helper function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Helper function to get the current time in HH:MM format
  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const [inspectionType, setInspectionType] = useState("pre-trip"); 
  const [formData, setFormData] = useState({
    date: getTodayDate(),
    time: getCurrentTime(), // Autopopulate with current time
    truckId: "",
    trailerId: "",
    odometer: "",
    checklist: {
      " Air Compressor": false,
      " Battery": false,
      " Body": false,
      " Brakes Parking": false,
      " Brakes Service": false,
      " Brake Tag": false,
      " Coupling Devices": false,
      " Defroster Heater": false,
      " Exhaust": false,
      " Engine Compartment": false,
      " Fluids": false,
      " Frame Assembly": false,
      " Front Axle": false,
      " Horn": false,
      " Insurance Papers": false,
      " Mirrors": false,
      " Registration": false,
      " Lights": false,
      " Reflectors": false,
      " Safety Equipment": false,
      " Suspension System": false,
      " Steering": false,
      " Tires": false,
      " Wheels Rims": false,
      " Windows": false,
      " Windshield Wipers": false,
      " Fuel Tank": false,
      " Other": false,
    },
    trailerChecklist: {
      " Brakes": false,
      " Coupling Devices": false,
      " Chains/Binders/Ratchet Straps": false,
      " Cargo Secured": false,
      " Landing Gear": false,
      " Lights-- All": false,
      " Suspension System": false,
      " Tires--All": false,
      " Wheels Rims": false,
      " Other": false,
    },
    comments: "",
    defectsCorrected: false,
    defectsNotNeeded: false,
  });

  const [trucks, setTrucks] = useState([]);
  const signatureRef = useRef(null);
  const [filteredTrucks, setFilteredTrucks] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [otherChecklistItem, setOtherChecklistItem] = useState("");
  const [otherTrailerChecklistItem, setOtherTrailerChecklistItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [assignedVehicles, setAssignedVehicles] = useState([]);

  useEffect(() => {
    if (location.state?.truckId) {
      setFormData(prev => ({ ...prev, truckId: location.state.truckId }));
      fetchTruckMileage(location.state.truckId); // Fetch mileage for selected truck
    }
  }, [location.state]);

  

  useEffect(() => {
    const fetchTrucks = async () => {
      const truckCollection = collection(db, "Trucks");
      const truckSnapshot = await getDocs(truckCollection);
      setTrucks(truckSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchTrucks();
  }, []);

  // Handle checklist changes
  const handleInputChange = async (e) => {
    const { name, value, type, checked } = e.target;
  
    if (type === "checkbox") {
      const [section, field] = name.split(".");
  
      if (field === "Other") {
        return; // 🚨 Prevents "Other" from being saved as a boolean
      }
  
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: checked,
        },
      }));
  
      if (!formData.truckId) return; // Prevent Firestore updates if no truck is selected
  
      try {
        const inspectionRef = doc(db, "vehicleInspections", formData.truckId);
        const inspectionDoc = await getDoc(inspectionRef);
        let updatedFirestoreChecklist = inspectionDoc.exists()
          ? { ...inspectionDoc.data()[section] }
          : {};
  
        updatedFirestoreChecklist[field] = checked;
  
        await setDoc(
          inspectionRef,
          {
            [section]: updatedFirestoreChecklist,
            truckId: formData.truckId,
            date: formData.date,
            time: formData.time,
          },
          { merge: true }
        );
      } catch (error) {
        console.error("Error updating checklist in Firebase:", error);
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  

// Handle changes for the "Other" item in truck checklist
const handleOtherChecklistChange = async (e) => {
  const otherValue = e.target.value.trim();

  setOtherChecklistItem(otherValue); // Keep UI updated with user input

  setFormData((prev) => {
    const updatedChecklist = { ...prev.checklist };

    if (otherValue) {
      updatedChecklist[" Other"] = otherValue; // Store as a string instead of boolean
    } else {
      delete updatedChecklist[" Other"]; // Remove from state when empty
    }

    return { ...prev, checklist: updatedChecklist };
  });

  if (!formData.truckId) return; // Prevent Firestore updates if no truck is selected

  try {
    const inspectionRef = doc(db, "vehicleInspections", formData.truckId);
    const inspectionDoc = await getDoc(inspectionRef);
    let updatedFirestoreChecklist = inspectionDoc.exists()
      ? { ...inspectionDoc.data().checklist }
      : {};

    if (otherValue) {
      updatedFirestoreChecklist[" Other"] = otherValue; // Store as string
    } else {
      delete updatedFirestoreChecklist[" Other"]; // Remove if empty
    }

    await setDoc(inspectionRef, { checklist: updatedFirestoreChecklist }, { merge: false });
  } catch (error) {
    console.error("Error updating 'Other' checklist item in Firebase:", error);
  }
};


// Handle changes for the "Other" item in trailer checklist
const handleOtherTrailerChecklistChange = async (e) => {
  const otherValue = e.target.value.trim();

  setOtherTrailerChecklistItem(otherValue);

  setFormData((prev) => {
    const updatedTrailerChecklist = { ...prev.trailerChecklist };

    if (otherValue) {
      updatedTrailerChecklist[" Other"] = otherValue;
    } else {
      delete updatedTrailerChecklist[" Other"];
    }

    return { ...prev, trailerChecklist: updatedTrailerChecklist };
  });

  if (!formData.trailerId) return;

  try {
    const inspectionRef = doc(db, "vehicleInspections", formData.trailerId);
    const inspectionDoc = await getDoc(inspectionRef);
    let updatedFirestoreTrailerChecklist = inspectionDoc.exists()
      ? { ...inspectionDoc.data().trailerChecklist }
      : {};

    if (otherValue) {
      updatedFirestoreTrailerChecklist[" Other"] = otherValue;
    } else {
      delete updatedFirestoreTrailerChecklist[" Other"];
    }

    await setDoc(inspectionRef, { trailerChecklist: updatedFirestoreTrailerChecklist }, { merge: false });
  } catch (error) {
    console.error("Error updating 'Other' trailer checklist item in Firebase:", error);
  }
};





  const handleBack = () => {
    if (!currentUser) return navigate('/login'); // Default to login if no user

    switch (currentUser.role) {
      case 'supervisor':
        navigate('/supervisor-dashboard');
        break;
      case 'manager':
        navigate('/manager-dashboard');
        break;
      case 'admin':
        navigate('/admin-dash');
        break;
      default:
        navigate('/'); // Default fallback
    }
  };



const handleSignatureClear = () => {
    signatureRef.current.clear();
  };

const updateTruckMileage = async (truckId, newMileage) => {
    try {
      const truckRef = doc(db, "Trucks", truckId);
  
      // Update mileage in the Firestore document
      await updateDoc(truckRef, { mileage: newMileage });
      console.log(`Truck ${truckId} mileage updated to ${newMileage}`);
    } catch (error) {
      console.error("Error updating truck mileage:", error);
      alert("Failed to update truck mileage. Please try again.");
    }
  };

  const handleTruckIdChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, truckId: value }));

    if (value.trim() === "") {
      setFilteredTrucks([]);
      setShowSuggestions(false);
    } else {
      const filtered = trucks.filter((truck) =>
        truck.vehicleName?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTrucks(filtered);
      setShowSuggestions(true);
    }
  };

  const handleTruckSelect = (truck) => {
    setFormData((prev) => ({ ...prev, truckId: truck.id }));
    setShowSuggestions(false);
  };

  const fetchTruckMileage = async (truckId) => {
    try {
      const truckRef = doc(db, "Trucks", truckId);
      const truckDoc = await getDoc(truckRef);
      if (truckDoc.exists()) {
        const currentMileage = truckDoc.data().mileage || 0;
        setMileage(currentMileage); // Set mileage from Firestore
      } else {
        console.error("Truck not found in Firestore.");
      }
    } catch (error) {
      console.error("Error fetching truck mileage:", error);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const signatureData =
      signatureRef.current && !signatureRef.current.isEmpty()
        ? signatureRef.current.getTrimmedCanvas().toDataURL("image/png")
        : null;
  
    if (!signatureData) {
      alert("Please provide your signature before submitting.");
      return;
    }
  
    if (!formData.truckId) {
      alert("Please select a truck.");
      return;
    }
  
    if (inspectionType === "post-trip" && (!formData.odometer || isNaN(formData.odometer))) {
      alert("Please enter a valid odometer reading for post-trip.");
      return;
    }
  
    try {
      const truckRef = doc(db, "Trucks", formData.truckId);
      const truckDoc = await getDoc(truckRef);
  
      if (!truckDoc.exists()) {
        alert("Error: Truck not found.");
        return;
      }
  
      const truckData = truckDoc.data();
      const previousMileage = truckData.mileage || 0;
      const newMileage = parseFloat(formData.odometer);
  
      if (newMileage < previousMileage) {
        alert(
          `Invalid mileage entry. The new mileage (${newMileage}) cannot be less than the previous mileage (${previousMileage}).`
        );
        return;
      }
  
      // **Update Truck Mileage**
      await updateDoc(truckRef, { mileage: newMileage });
  
      // ✅ Remove boolean "Other" fields before saving new ones
      let updatedChecklist = { ...formData.checklist };
      let updatedTrailerChecklist = { ...formData.trailerChecklist };
  
      // 🔹 **Ensure that "Other" boolean is completely removed**
      delete updatedChecklist[" Other"];
      delete updatedTrailerChecklist[" Other"];
  
      // ✅ Only store "Other" if there's a user-entered value
      if (otherChecklistItem.trim() !== "") {
        updatedChecklist[`Other: ${otherChecklistItem}`] = true;
      }
  
      if (otherTrailerChecklistItem.trim() !== "") {
        updatedTrailerChecklist[`Other: ${otherTrailerChecklistItem}`] = true;
      }
  
      // ✅ Save the inspection as a new document
      const inspectionsRef = collection(db, "vehicleInspections");
      await addDoc(inspectionsRef, {
        truckId: formData.truckId,
        date: formData.date || new Date().toISOString(),
        time: formData.time || new Date().toLocaleTimeString(),
        inspectionType: inspectionType,
        odometer: newMileage,
        comments: formData.comments || "",
        signature: signatureData,
        timestamp: new Date().toISOString(),
        checklist: updatedChecklist,
        trailerChecklist: updatedTrailerChecklist,
      });
  
      alert("Checklist submitted successfully!");
      navigate("/supervisor-dashboard");
    } catch (error) {
      console.error("Error saving checklist:", error);
      alert("Failed to save checklist. Please try again.");
    }
  };
  
  
  const assignVehicleToUser = async () => {
    if (!selectedVehicle) {
      alert('Please select a vehicle.');
      return;
    }

    try {
      const vehicleRef = doc(db, 'Trucks', selectedVehicle.id);
      await updateDoc(vehicleRef, { assignedTo: currentUser.email });
      alert(`Vehicle ${selectedVehicle.vehicleName} assigned to you.`);
      setAssignedVehicles((prev) => [...prev, { ...selectedVehicle, assignedTo: currentUser.email }]);
      setSearchTerm('');
      setSelectedVehicle(null);
    } catch (error) {
      console.error('Error assigning vehicle:', error);
      alert('Failed to assign vehicle.');
    }
  };
  

  return (
    <form onSubmit={handleSubmit} className="checklist-form">
      <h1 className="form-title">{t('Vehicle Inspection Checklist')}</h1>
      <button
  type="button"
  onClick={handleBack}
  style={{
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "transparent", // Removes the grey container
    color: "#296b1a", // Green text
    fontSize: "1rem",
    fontWeight: "bold",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
  }}
>
  ← Back to Dashboard
</button>

      <div className="form-group">
        <label>{t('Inspection Type')}</label>
        <div className="toggle-buttons">
          <label>
            <input
              type="radio"
              name="inspectionType"
              value="pre-trip"
              checked={inspectionType === "pre-trip"}
              onChange={() => setInspectionType("pre-trip")}
            />
            {t('Pre-Trip')}
          </label>
          <label>
            <input
              type="radio"
              name="inspectionType"
              value="post-trip"
              checked={inspectionType === "post-trip"}
              onChange={() => setInspectionType("post-trip")}
            />
            {t('Post-Trip')}
          </label>
        </div>
      </div>

      

      <div className="form-group">
        
        <label>{t('Date')}</label>
        <input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleInputChange}
          required
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label>{t('Time')}</label>
        <input
          type="time"
          name="time"
          value={formData.time}
          onChange={handleInputChange}
          required
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label>{t("Truck ID")}</label>
        <div style={{ position: "relative" }}>
          <input
            type="text"
            name="truckId"
            value={formData.truckId}
            onChange={handleTruckIdChange}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => setTimeout(() => setShowSuggestions(false), 150)}
            placeholder="Type or select a truck"
            className="form-control"
          />
          {showSuggestions && filteredTrucks.length > 0 && (
            <ul
              style={{
                listStyle: "none",
                padding: 0,
                margin: "0.5rem 0 0",
                border: "1px solid #ccc",
                backgroundColor: "#fff",
                position: "absolute",
                zIndex: 10,
                maxHeight: "150px",
                overflowY: "auto",
                width: "100%",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              {filteredTrucks.map((truck) => (
                <li
                  key={truck.id}
                  onClick={() => handleTruckSelect(truck)}
                  style={{
                    padding: "0.5rem",
                    cursor: "pointer",
                  }}
                >
                  {truck.vehicleName || `Truck ${truck.id}`}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>


      <div className="form-group">
        <label>{t('Trailer ID')}</label>
        <input
          type="text"
          name="trailerId"
          value={formData.trailerId}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group">
  <label>{t('Odometer Reading')}</label>
  <input
    type="number"
    name="odometer"
    value={mileage || formData.odometer} // Use mileage if available, else formData.odometer
    onChange={handleInputChange}
    readOnly={inspectionType === "pre-trip"} // Make field read-only for pre-trip
    required
    className="form-control"
  />
</div>


{/* Truck Checklist */}
<h2 className="section-title">{t('Truck Inspection')}</h2>
<div className="checklist-section">
  {Object.keys(formData.checklist).map((key) => (
    <div key={key} className="checkbox-item">
      <label>
        <input
          type="checkbox"
          name={`checklist.${key}`}
          checked={formData.checklist[key]}
          onChange={handleInputChange}
        />
        {t(key)}
      </label>
    </div>
  ))}

  {/* "Other" Checkbox with Text Input */}
  <div className="checkbox-item">
    
    {formData.checklist[" Other"] && (
      <input
        type="text"
        placeholder="Describe issue"
        value={otherChecklistItem}
        onChange={(e) => setOtherChecklistItem(e.target.value)}
        className="form-control"
      />
    )}
  </div>
</div>


{/* Trailer Checklist */}
<h2 className="section-title">{t('Trailer Inspection')}</h2>
<div className="checklist-section">
  {Object.keys(formData.trailerChecklist).map((key) => (
    <div key={key} className="checkbox-item">
      <label>
        <input
          type="checkbox"
          name={`trailerChecklist.${key}`}
          checked={formData.trailerChecklist[key]}
          onChange={handleInputChange}
        />
        {t(key)}
      </label>
    </div>
  ))}

  {/* "Other" Checkbox with Text Input */}
  <div className="checkbox-item">
    
    {formData.trailerChecklist[" Other"] && (
      <input
        type="text"
        placeholder="Describe issue"
        value={otherTrailerChecklistItem}
        onChange={(e) => setOtherTrailerChecklistItem(e.target.value)}
        className="form-control"
      />
    )}
  </div>
</div>



      <div className="form-group">
  <label>{t('Driver Signature')}</label>
  <div className="signature-container">
    <SignatureCanvas
      ref={signatureRef}
      penColor="black"
      canvasProps={{
        className: "signature-canvas",
        style: { border: "1px dashed #ccc", width: "100%", height: "150px" },
      }}
    />
    <p className="signature-hint">{t('Sign within the box')}</p>
    <button
      type="button"
      onClick={handleSignatureClear}
      className="clear-button"
    >
      {t('Clear Signature')}
    </button>
  </div>
</div>
     

      <button type="submit" className="submit-button">{t('Submit Checklist')}</button>
    </form>
  );
};

export default ChecklistForm;
