import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../hooks/AuthProvider';
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';

const SectionTitle = styled.h2`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
  color: #333;

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  justify-content: center;
  gap: 0.75rem;
  margin: 1rem 0;

  button {
    flex: 1 1 auto; /* Ensure buttons scale to fit */
    max-width: 200px; /* Limit button width on larger screens */
  }
`;

const TaskContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 600px) {
    gap: 0.75rem;
  }
`;

const TaskCard = styled.div`
  border: ${({ completed }) => (completed ? '1px solid #c3e6cb' : '1px solid #ffeeba')};
  background: ${({ completed }) => (completed ? '#d4edda' : '#fff3cd')};
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  p {
    margin: 0.25rem 0;
    font-size: 0.9rem;

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  strong {
    color: #555;
  }
`;

const ProgressText = styled.p`
  font-size: 0.9rem;
  color: ${({ completed }) => (completed ? '#080808' : '#080808')};
`;

const Modal = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const StyledButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 9999px;
  cursor: pointer;

  &:hover {
    background-color: #219653;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 600px) {
    padding: 0.4rem 0.75rem; /* Smaller padding for mobile */
  }
`;

const ModalOverlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 90%; /* Make modal width flexible */
  max-width: 400px; /* Limit modal size on larger screens */
  max-height: 80vh;
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  @media (min-width: 601px) {
    width: 600px;
    max-height: 80vh;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
  color: #333;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.5rem;

  &:hover {
    background: #ccc;
  }
`;

const ImageNoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;

  img {
    width: 100%; /* Full width for mobile */
    height: auto; /* Maintain aspect ratio */
    max-width: 80px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (min-width: 601px) {
      width: 80px;
    }
  }

  label {
    font-size: 0.85rem;

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  appearance: none; /* Remove default styling */
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:checked {
    background-color: #27ae60; /* Green background when checked */
    border-color: #27ae60; /* Match border color to the background */
  }

  &:checked::before {
    content: '✔'; /* Unicode for checkmark */
    color: white;
    font-size: 14px;
    display: block;
    text-align: center;
    line-height: 20px; /* Center the checkmark */
  }
`;


const ImagePreviewModal = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  justify-content: center;
  align-items: center;
  z-index: 1100;

  img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 300px; /* Reduce height for smaller screens */

  @media (min-width: 601px) {
    height: 450px;
  }
`;

const SupervisorDashboard = () => {
  const { t } = useTranslation();
  const { currentUser: user, loading: authLoading } = useContext(AuthContext);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);

  const selectedTask = assignedTasks.find((task) => task.id === selectedTaskId);

  const parseDate = (dateString) => {
    if (!dateString) return new Date('Invalid Date'); // Fallback for missing dates
    const [month, day, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };
  

  useEffect(() => {
    const purgeOldTasks = async () => {
      const currentDate = new Date();
  
      const q = query(
        collection(db, 'tasks'),
        where('supervisorEmail', '==', user.email)
      );
      const querySnapshot = await getDocs(q);
  
      querySnapshot.docs.forEach(async (doc) => {
        const task = doc.data();
        const dueDate = new Date(task.completionWeek);
  
        if (!isNaN(dueDate) && (currentDate - dueDate) / (1000 * 60 * 60 * 24) > 30) {
          try {
            await deleteDoc(doc.ref);
          } catch (err) {
            console.error(`Error deleting task ${doc.id}:`, err);
          }
        }
      });
    };
  
    purgeOldTasks();
  }, [user]);
  

  useEffect(() => {
    if (!user) return;
  
    const fetchTasks = async () => {
      try {
        const q = query(
          collection(db, 'tasks'),
          where('supervisorEmail', '==', user.email)
        );
        const querySnapshot = await getDocs(q);
  
        const currentDate = new Date();
        const tasks = querySnapshot.docs
  .map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }))
  .filter((task) => {
    const dueDate = new Date(task.completionWeek);
    // Exclude tasks more than 30 days past due date
    return !isNaN(dueDate) && (dueDate >= currentDate || (currentDate - dueDate) / (1000 * 60 * 60 * 24) <= 30);
  })
  .sort((a, b) => {
    const dueDateA = new Date(a.completionWeek);
    const dueDateB = new Date(b.completionWeek);

    // Ensure valid date objects and sort them
    return dueDateA - dueDateB;
  });

  
        // Update the task list
        setAssignedTasks(tasks);
      } catch (err) {
        console.error('Error fetching tasks:', err);
        setError('Failed to fetch tasks.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchTasks();
  }, [user]);
  

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const toggleVideoModal = () => {
    setVideoModalOpen(!isVideoModalOpen);
  };

  const openTaskModal = (taskId) => {
    setSelectedTaskId(taskId);
    setModalOpen(true);
  };

  const closeTaskModal = () => {
    setSelectedTaskId(null);
    setModalOpen(false);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImagePreview = () => {
    setSelectedImage(null);
  };

  const handleTaskCompletion = async (taskId, imageIndex, isCompleted) => {
    setAssignedTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId
          ? {
              ...task,
              tasks: task.tasks.map((image, index) =>
                index === imageIndex ? { ...image, completed: isCompleted } : image
              ),
            }
          : task
      )
    );

    try {
      const taskRef = doc(db, 'tasks', taskId);
      const task = assignedTasks.find((t) => t.id === taskId);

      if (task) {
        const updatedTasks = task.tasks.map((image, index) =>
          index === imageIndex ? { ...image, completed: isCompleted } : image
        );

        await updateDoc(taskRef, { tasks: updatedTasks });
      }
    } catch (err) {
      console.error('Error updating task completion:', err);

      setAssignedTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId
            ? {
                ...task,
                tasks: task.tasks.map((image, index) =>
                  index === imageIndex
                    ? { ...image, completed: !isCompleted }
                    : image
                ),
              }
            : task
        )
      );
    }
  };

  const handleSubmitTask = async () => {
    if (!selectedTask) return;

    try {
      const taskRef = doc(db, 'tasks', selectedTask.id);
      await updateDoc(taskRef, { completed: true });

      setAssignedTasks((prevTasks) =>
        prevTasks.filter((task) => task.id !== selectedTask.id)
      );

      closeTaskModal();
    } catch (err) {
      console.error('Error submitting completed task:', err);
    }
  };

  const allItemsCompleted =
    selectedTask?.tasks?.length > 0 &&
    selectedTask.tasks.every((item) => item.completed);

  if (authLoading) return <p>Loading authentication...</p>;

  return (
    <div className="supervisor-dashboard">
      <div className="supervisor-dashboard">
      <SectionTitle>{t('Supervisor Dashboard')}</SectionTitle>
      <ButtonContainer>
        <StyledButton onClick={() => navigate('/job-form')}>{t('New Service Update')}</StyledButton>
        <StyledButton onClick={() => navigate('/supervisor-job-list')}>{t('Services Needing Approval')}</StyledButton>
        <StyledButton onClick={() => navigate('/supervisor-trip-inspection')}>{t('Trip Inspection')}</StyledButton>
        <StyledButton onClick={() => navigate('/inspection-history')}>{t('Inspection History')}</StyledButton> 
        <StyledButton type="button" onClick={toggleModal}>
          {t('Watch Tutorial Video')}
        </StyledButton>
      </ButtonContainer>
      </div>
      <SectionTitle>{t('My Punch List')}</SectionTitle>

      {loading && <p>Loading tasks...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <TaskContainer>
        {assignedTasks.map((task) => {
          const completedSubtasks = task.tasks?.filter((subtask) => subtask.completed).length || 0;
          const totalSubtasks = task.tasks?.length || 0;
          const isCompleted = completedSubtasks === totalSubtasks && totalSubtasks > 0;

          return (
            <TaskCard
              key={task.id}
              completed={isCompleted}
              onClick={() => openTaskModal(task.id)}
            >
              <p>
                <strong>Property:</strong> {task.propertyName}
              </p>
              <p>
                <strong>Assigned By:</strong> {task.assignedBy?.name || 'Unknown'}
              </p>
              <p>
                <strong>Due Date:</strong> {task.completionWeek || 'No Due Date'}
              </p>
              <ProgressText completed={isCompleted}>
                {completedSubtasks}/{totalSubtasks} Tasks Completed
              </ProgressText>
            </TaskCard>
          );
        })}
      </TaskContainer>

      <ModalOverlay isOpen={isModalOpen}>
        <ModalContent>
          <CloseButton onClick={closeTaskModal}>&times;</CloseButton>
          {selectedTask && (
            <>
              <h3>{selectedTask.propertyName}</h3>
              <p><strong>Assigned By:</strong> {selectedTask.assignedBy?.name || 'Unknown'}</p>
              <p><strong>Due Date:</strong> {selectedTask.completionWeek || 'No Due Date'}</p>

              {selectedTask.tasks && selectedTask.tasks.length > 0 ? (
                selectedTask.tasks.map((taskItem, index) => (
                  <ImageNoteWrapper key={index}>
                    {taskItem.url ? (
                      <img
                        src={taskItem.url}
                        alt={`Task ${index + 1}`}
                        onClick={() => handleImageClick(taskItem.url)}
                      />
                    ) : null}
                    <label>
                      <Checkbox
                        type="checkbox"
                        checked={taskItem.completed || false}
                        onChange={(e) =>
                          handleTaskCompletion(selectedTask.id, index, e.target.checked)
                        }
                      />
                      {taskItem.note || 'No note provided'}
                    </label>
                  </ImageNoteWrapper>
                ))
              ) : (
                <p>No tasks associated with this task.</p>
              )}
            </>
          )}
        </ModalContent>
      </ModalOverlay>

      <ImagePreviewModal isOpen={!!selectedImage}>
        <img src={selectedImage} alt="Preview" onClick={closeImagePreview} />
      </ImagePreviewModal>

      {/* Video Modal */}
      <Modal isOpen={isVideoModalOpen} onClick={toggleVideoModal}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={toggleVideoModal}>✖</CloseButton>
          <Iframe
            src="https://www.youtube.com/embed/LJoA59hKZbI"
            title="Tutorial Video"
          />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SupervisorDashboard;
