import React, { useState, useEffect, useRef } from "react";
import { db } from "../Firebase Functions/firebase";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, LineController, Chart } from "chart.js";
import 'chartjs-adapter-date-fns'; // Import the date adapter
import "./UserManagement.css";

// Register necessary components for chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineController // Register the LineController for line charts
);

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [managerData, setManagerData] = useState([]);
  const [supervisorData, setSupervisorData] = useState([]);
  const chartRef = useRef(null); // Reference for the chart instance

  // Helper function to group users by the creation date (day) and type (manager or supervisor)
  const groupByDateAndType = (users) => {
    const dateCounts = {};

    users.forEach((user) => {
      // Ensure we are using the correct timestamp format
      const createdAt = user.created_at ? new Date(user.created_at.seconds * 1000) : null;
      if (createdAt) {
        const dateKey = createdAt.toISOString().split("T")[0]; // Get the date in YYYY-MM-DD format

        if (!dateCounts[dateKey]) {
          dateCounts[dateKey] = { manager: 0, supervisor: 0 };
        }

        // Increment the count based on user type
        if (user.type === "manager") {
          dateCounts[dateKey].manager += 1;
        } else if (user.type === "supervisor") {
          dateCounts[dateKey].supervisor += 1;
        }
      }
    });

    // Convert the dateCounts object into an array of data points
    return Object.entries(dateCounts).map(([date, counts]) => ({
      x: new Date(date), // Date as the x-axis value
      manager: counts.manager,
      supervisor: counts.supervisor
    }));
  };

  const fetchUsers = async () => {
    const userCollection = collection(db, "Users");
    const userSnapshot = await getDocs(userCollection);
    const userList = userSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    setUsers(userList);

    // Group users by creation date and type (manager or supervisor)
    const groupedData = groupByDateAndType(userList);

    // Set manager and supervisor data separately
    setManagerData(groupedData.map(data => ({
      x: data.x,
      y: data.manager
    })));

    setSupervisorData(groupedData.map(data => ({
      x: data.x,
      y: data.supervisor
    })));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const chartData = {
        datasets: [
          {
            label: 'Manager Accounts',
            data: managerData,
            borderColor: 'blue',
            backgroundColor: 'rgba(0, 123, 255, 0.2)',
            fill: false,
            pointRadius: 5, // Dots for each user
            lineTension: 0.4, // To smoothen the line
          },
          {
            label: 'Supervisor Accounts',
            data: supervisorData,
            borderColor: 'green',
            backgroundColor: 'rgba(40, 167, 69, 0.2)',
            fill: false,
            pointRadius: 5, // Dots for each user
            lineTension: 0.4, // To smoothen the line
          }
        ],
      };

      const chartOptions = {
        scales: {
          x: {
            type: 'time',  // Use time scale
            time: {
              unit: 'day',  // Unit of time to display (could also be 'month', 'hour', etc.)
              tooltipFormat: 'PP', // Formatting for tooltips
              displayFormats: {
                day: 'MMM d, yyyy', // Format date as Month day, Year
              }
            },
            title: {
              display: true,
              text: 'Account Creation Date',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Number of Users',
            },
            ticks: {
              beginAtZero: true, // Start at zero
              stepSize: 1,  // Set the increment step to 1
            },
          },
        },
        responsive: true, // Ensure the chart is responsive
        animation: {
          duration: 1000,
          easing: 'easeOutQuad',
        },
      };

      // Destroy any previous chart instance before creating a new one
      const chartInstance = Chart.getChart(chartRef.current); // Get the existing chart instance
      if (chartInstance) {
        chartInstance.destroy(); // Destroy it
      }

      // Create the new chart
      new Chart(chartRef.current, {
        type: 'line',
        data: chartData,
        options: chartOptions,
      });
    }

    // Cleanup chart instance on unmount
    return () => {
      if (chartRef.current) {
        const chartInstance = Chart.getChart(chartRef.current);
        if (chartInstance) {
          chartInstance.destroy();
        }
      }
    };
  }, [managerData, supervisorData]); // Re-render and update chart when data changes

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this user?");
    if (!isConfirmed) return; // If the user cancels, do nothing
  
    try {
      await deleteDoc(doc(db, "Users", id));
      setUsers(users.filter(user => user.id !== id));
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Failed to delete the user. Please try again.");
    }
  };
  

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const updatedUser = {
      email: formData.get("email"),
      first_name: formData.get("first_name"),
      last_name: formData.get("last_name"),
    };
  
    console.log("Updating user:", updatedUser); // Debugging
  
    try {
      if (editingUser) {
        const userRef = doc(db, "Users", editingUser.id);
        await updateDoc(userRef, updatedUser); // Firestore update
        setUsers(users.map(user => (user.id === editingUser.id ? { ...user, ...updatedUser } : user)));
      } else {
        const docRef = await addDoc(collection(db, "Users"), updatedUser);
        setUsers([...users, { id: docRef.id, ...updatedUser }]);
      }
      setEditingUser(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating Firestore:", error);
      alert("Failed to update Firestore. Check Firestore rules.");
    }
  };
  
  

  return (
    <div className="user-management">
      <h2>User Management</h2>
      <button className="add-user-btn" onClick={() => { setEditingUser(null); setIsModalOpen(true); }}>
        Add User
      </button>

      <canvas ref={chartRef} width="400" height="200"></canvas>

      <table className="user-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>{user.email}</td>
              <td>{user.type}</td>
              <td>
                <button className="edit-btn" onClick={() => { setEditingUser(user); setIsModalOpen(true); }}>
                  Edit
                </button>
                <button className="delete-btn" onClick={() => handleDelete(user.id)}>
                  Delete
                </button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-btn" onClick={() => setIsModalOpen(false)}>✖</button>
            <form onSubmit={handleSave} className="modal-form">
              <h3 className="modal-title">{editingUser ? "Edit User" : "Add User"}</h3>
              <label>First Name</label>
              <input name="first_name" placeholder="First Name" defaultValue={editingUser?.first_name || ""} required className="modal-input" />
              <label>Last Name</label>
              <input name="last_name" placeholder="Last Name" defaultValue={editingUser?.last_name || ""} required className="modal-input" />
              <label>Email</label>
              <input name="email" type="email" placeholder="Email" defaultValue={editingUser?.email || ""} required className="modal-input" />
              <label>Role</label>
              <input name="type" placeholder="Role" defaultValue={editingUser?.type || ""} required className="modal-input" />
              <button type="submit" className="modal-submit-btn">Save</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
